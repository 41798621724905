<template>
  <article class="faq-column">
    <h2 id="mobile-app-faq-heading">Frequently asked questions about the mobile app</h2>
    <accordion header-id="mobile-app-faq-heading">
      <accordion-item
        title="How do I use my membership plan to start a charging session at an  Electrify America charger?"
      >
        <p>
          As an Electrify America Pass or Pass+ member, if you’ve downloaded the Electrify America app and added a valid
          payment method to your account, you have two ways to start a charge by smartphone:
        </p>
        <div class="flex">
          <div class="body">
            <p>
              1. Using the
              <router-link class="link" :to="{ name: 'glossary-en', query: { load: 9 }, hash: '#mobile-app' }"
                >digital membership pass</router-link
              >, which requires tapping or holding the smartphone near the charger’s RFID reader.
            </p>
            <ul>
              <li>
                First, plug the connector into the vehicle. Follow all steps on the charger screen before tapping or
                holding your phone near the reader.
              </li>
              <li>
                <b>Android users:</b> Ensure that NFC is turned on in your phone settings and that the smartphone screen
                is unlocked before tapping the smartphone to the reader—look for the symbol below. The Electrify America
                app does not need to be open.
              </li>
              <li>
                <b>iOS users:</b> Open Apple Wallet or the Electrify America app. Tap the smartphone to the reader on
                the right side of the charger—look for the symbol shown here
              </li>
            </ul>
            <p>2. Using “Swipe to Start Charge” from within the Electrify America app.</p>
            <ul>
              <li>
                Ensure that your smartphone’s location services are enabled, the Electrify America app is installed on
                your phone, and you are logged into your account.
              </li>
              <li>
                Open the app and from the map screen (or your Favorites), select the charging station where you are
                located. You must be near the charger to start a charge.
              </li>
              <li>
                Select the charger by referring to the Charger ID number at the top of the charger’s screen, and swipe
                to start a charging session.
              </li>
            </ul>
          </div>
          <div class="images">
            <img
              loading="lazy"
              class="image"
              src="@/assets/images/Mobile-App/tap-to-pay.png"
              alt="Diagram showing how to tap a smartphone to the charging station’s RFID reader."
            />
            <SwipeToStart class="image" alt="The mobile app’s “Swipe to Start Charge” slider." />
          </div>
        </div>
      </accordion-item>

      <accordion-item title="How do I pay for charging and for the Pass+ subscription fee?">
        <p>
          When you sign up for Electrify America Pass or Pass+ in the mobile app, follow the prompts to add your credit
          card to your account. Then select an amount to automatically load money to your Electrify America balance.
        </p>
        <p>
          This amount will be added to your account the first time you charge your vehicle at an Electrify America
          charging station after signing up. Every time your balance falls to or below $5, your account will reload with
          the amount you selected.
        </p>
        <p>
          The cost for charging, idling, and any applicable taxes will be deducted from your balance at the end of your
          charging session.
        </p>
        <p>
          If you’re a Pass+ member, your monthly subscription fee will be charged to your credit or debit card when you
          sign up and deducted from your Electrify America balance monthly thereafter.
        </p>
      </accordion-item>

      <accordion-item title="What happens if my Electrify America account runs out of money?">
        <p>
          If your payment method is declined during a charging session, please update it to allow Electrify America to
          auto-fill your account and continue charging your vehicle.
        </p>
        <p>If the payment method is not updated, charging will stop when your account balance reaches $0.</p>
      </accordion-item>

      <accordion-item title="Will I need to create a new account to use the new app?">
        <p>No, you can use your existing Electrify America account credentials to sign in to the new app.</p>
      </accordion-item>
      <accordion-item title="Can I use the Electrify America app with CarPlay and Android Auto?">
        <p>
          Yes! Please ensure your vehicle is compatible with CarPlay or Android Auto and your phone is up to date. Here
          are a few ways you can use the Electrify America app in vehicles compatible with CarPlay and Android Auto:
        </p>
        <ul>
          <li>Navigate to charging stations while on the go</li>
          <li>View charger details</li>
          <li>Manage your charging session</li>
        </ul>
      </accordion-item>
      <accordion-item
        title="What’s the latest version of software needed to use the Electrify America app with CarPlay and Android Auto?"
      >
        <p>The Electrify America app is supported on both iOS 16+ and Android 9+.</p>
      </accordion-item>
    </accordion>
  </article>
</template>

<script>
import SwipeToStart from '@/assets/images/Mobile-App/swipe to start.svg';
import Accordion from './Accordion/Accordion.vue';
import AccordionItem from './Accordion/AccordionItem.vue';

export default {
  name: 'faqs',
  components: {
    Accordion,
    AccordionItem,
    SwipeToStart,
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
  },
};
</script>

<style scoped lang="scss">
.flex {
  flex-direction: column;
  @media (min-width: 750px) {
    flex-direction: row;
  }

  .body {
    flex: 1;
  }

  .images {
    margin-left: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    @media (min-width: 750px) {
      flex-direction: column;
    }
    .image {
      width: 240px;
      height: auto;
    }

    .image:nth-child(1) {
      @media (max-width: 750px) {
        margin-bottom: 30px;
      }
    }

    .image:nth-child(2) {
      max-height: 70px;
    }
  }
}
</style>
